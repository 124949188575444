/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

const $ = window.jQuery = require('jquery');
const bootstrap = window.bootstrap = require('bootstrap');
const Cookies = require('js-cookie');

var md_cookie_allow = Cookies.get('md_cookie_allow');
if (md_cookie_allow !== undefined) md_cookie_allow = JSON.parse(md_cookie_allow);
var locale = null;
var currency_symbol = null;

function setCookies() {
    md_cookie_allow = {};
    $('#md_cookie_allow-undefined').find('input[type="checkbox"]').each(function () {
        md_cookie_allow[$(this).attr('name')] = $(this).prop('checked');
    });
    Cookies.set('md_cookie_allow', md_cookie_allow, { expires: 365, secure: true });
    addClids();
    processAdvertisingCode();
}

function getLocale() {
    return locale !== null ? locale : (locale = $('[name="locale"]').attr('content').replaceAll('_', '-'));
}

function getCurrencySymbol() {
    return currency_symbol !== null ? currency_symbol : (currency_symbol = $('[name="currency"]').attr('content'));
}

function addClids() {

    if (md_cookie_allow.advertising) {

        var url = new URL(window.location);

        var needPush = false;

        var $gclid = $('[data-gclid]');

        if ($gclid.length > 0) {

            var gclid = $gclid.attr('data-gclid');

            if (gclid && !location.href.match(/gclid=/)) {
                url.searchParams.set('gclid', gclid);
                needPush = true;
            }
        }

        var $fbclid = $('[data-fbclid]');

        if ($fbclid.length > 0) {

            var fbclid = $fbclid.attr('data-fbclid');

            if (fbclid && !location.href.match(/fbclid=/)) {
                url.searchParams.set('fbclid', fbclid);
                needPush = true;
            }
        }

        if (needPush) window.history.pushState({}, '', url);
    }
}

function processAdvertisingCode() {

    if (md_cookie_allow.advertising) {

        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
        });

    }
}

var basket_id = null;
var update_basket_timeout = null;
var update_basket_xhr = null;
var interaction_started = false;

$(document).ready(function () {

    // $(document).on({
    //     'show.bs.modal': function (e) {
    //         var zIndex = 1040 + (10 * $('.modal:visible').length);
    //         $(e.target).css('z-index', zIndex);
    //         setTimeout(function() {
    //             $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    //         }, 0);
    //     },
    //     'hidden.bs.modal': function() {
    //         if ($('.modal:visible').length > 0) {
    //             // restore the modal-open class to the body element, so that scrolling works
    //             // properly after de-stacking a modal.
    //             setTimeout(function() {
    //                 $(document.body).addClass('modal-open');
    //             }, 0);
    //         }
    //     }
    // }, '.modal');

    if (md_cookie_allow === undefined) {
        setTimeout(function () {
            $('#md_cookie_allow-undefined').modal('show');
        }, 3000);
    } else {
        if (md_cookie_allow.advertising) {
            processAdvertisingCode();
            $('#md_cookie_allow-checkbox-advertising').prop('checked', true);
        }
        $('#md_cookie_allow-set').removeClass('d-none');
    }

    $('#md_cookie_allow-button-all').on('click', function () {
        var $element = $('#md_cookie_allow-undefined');
        $element.find('input[type="checkbox"]').prop('checked', true);
        setCookies();
        $element.modal('hide');
        $('#md_cookie_allow-set').removeClass('d-none');
        return false;
    });

    $('#md_cookie_allow-button-save').on('click', function () {
        setCookies();
        $('#md_cookie_allow-undefined').modal('hide');
        $('#md_cookie_allow-set').removeClass('d-none');
        return false;
    });

    $('#md_cookie_allow-trigger').on('click', function () {
        $('#md_cookie_allow-undefined').modal('show');
        $('#md_cookie_allow-set').addClass('d-none');
        return false;
    });

    $('[data-auto-trigger="modal"]').on('shown.bs.modal', function () {
        $(this).addClass('fade');
        $('.modal-backdrop.show').addClass('fade');
    }).modal('show');

    $('[data-pay-pal-order]').each(initPayPalButtons);

    // var $navItems = $('#navbar a');

    // $(window).off('resize.fixed_top').on('resize.fixed_top', function () {
    //
    //     var $header = $('header');
    //     var $main = $('main');
    //
    //     if ($header.hasClass('fixed-top') && $(window).height() < $header.outerHeight() * 7) {
    //         $header.removeClass('fixed-top');
    //         $main.addClass('mt-0');
    //     } else if (!$header.hasClass('fixed-top') && $(window).height() >= $header.outerHeight() * 7) {
    //         $header.addClass('fixed-top');
    //         $main.removeClass('mt-0');
    //     }
    // }).trigger('resize');

    // $(document).off('scroll.mc').on('scroll.mc', function () {
    //
    //     var scrollPositionTop = $(this).scrollTop() + $('header').outerHeight() + 100;
    //
    //     var closestElementTop = -Number.MAX_SAFE_INTEGER;
    //     var $closestElement = null;
    //
    //     $navItems.each(function () {
    //
    //         if (!$(this).attr('href').match(/#/)) return;
    //
    //         var $contentElement = $('#'+$(this).attr('href').split('#')[1]);
    //
    //         if ($contentElement.length > 0) {
    //             if ($contentElement.offset().top - scrollPositionTop < 0 && $contentElement.offset().top - scrollPositionTop > closestElementTop) {
    //                 closestElementTop = $contentElement.offset().top - scrollPositionTop;
    //                 $closestElement = $(this);
    //             }
    //         }
    //     });
    //
    //     if ($closestElement !== null) {
    //         if ($closestElement.hasClass('text-white')) return;
    //         $navItems.removeClass('text-white');
    //         $closestElement.addClass('text-white');
    //     }
    // });
    //
    // $(document).trigger('scroll.mc');

    // $(window).off('hashchange').on('hashchange', hashChangeEvent);
    // $(window).trigger('hashchange');

    var $autoScrollElement = $('[data-auto-scroll="true"]');

    if ($autoScrollElement.length > 0) {
        $(document).scrollTop($autoScrollElement.offset().top - 320);
    }

    $('[data-rotate-target]').off('click.rotate').on('click.rotate', function () {
        var $this = $(this);
        var $target = $($this.attr('data-rotate-target'));
        $target.toggleClass('active');
    });

    $('[data-update-quantity-target]').off('click.quantity').on('click.quantity', function () {
        interaction_started = true;
        var $this = $(this);
        var $target = $($this.attr('data-update-quantity-target'));
        var step = parseInt($this.attr('data-step'));
        var value = parseInt($target.val().replace(/[^0-9]/, '')) + step;
        value = Math.max(Math.min(value, 9999), 0);
        $target.val(value);
        $target.trigger('change.quantity');
        return false;
    });

    $('input[data-price]').off('keyup.quantity-start').on('keyup.quantity-start', function () {
        if (!interaction_started) {
            interaction_started = true;
            $(this).trigger('change.quantity');
        }
        $(this).off('keyup.quantity-start');
    }).off('change.quantity keyup.quantity').on('change.quantity keyup.quantity', function () {

        if (update_basket_timeout) {
            clearTimeout(update_basket_timeout);
            update_basket_timeout = null;
        }

        if (update_basket_xhr) {
            update_basket_xhr.abort();
            update_basket_xhr = null;
        }

        var $this = $(this);
        var price = parseFloat($this.attr('data-price'));

        var quantity = parseInt($this.val().replace(/[^0-9]/, ''));

        if (!Number.isNaN(quantity)) {
            quantity = Math.max(Math.min(quantity, 9999), 0);
            $this.val(quantity);
        } else {
            quantity = 0;
        }

        if ($this.attr('data-trigger-quantity')) {
            $($this.attr('data-trigger-quantity')).trigger('change.quantity');
            return;
        }

        // var $items = $('[data-bs-target-free-qty="'+$this.attr('data-bs-target-free-qty')+'"]');
        //
        // var freeQty = 0;
        //
        // $items.each(function () {
        //
        //     var $item = $(this);
        //
        //     if ($item.attr('data-bs-toggle-free-qty')) {
        //         freeQty += parseInt($item.attr('data-bs-toggle-free-qty')) * parseInt($item.val().replace(/[^0-9]/, ''));
        //     }
        // });
        //
        // var $freeQtyTarget = $($this.attr('data-bs-target-free-qty'));
        // var $freeQtyTargetInfoText = $freeQtyTarget.find('[data-info-text]');
        // var $freeQtyInputs = $freeQtyTarget.find('input,button,select');
        // var $freeQtyInputFields = $freeQtyTarget.find('[data-price="0.0"]');
        // var $freeQtySum = $freeQtyTarget.find($this.attr('data-sum-free-qty'));
        //
        // $freeQtySum.text(freeQty);
        //
        // if (freeQty < 1) {
        //     $freeQtyTarget.addClass('opacity-50');
        //     $freeQtyTarget.removeClass('table-warning');
        //     $freeQtyTarget.addClass('bg-light');
        //     $freeQtyInputs.prop('disabled', true);
        //     $freeQtyTargetInfoText.hide();
        // } else {
        //     $freeQtyTarget.removeClass('opacity-50');
        //     $freeQtyTarget.addClass('table-warning');
        //     $freeQtyTarget.removeClass('bg-light');
        //     $freeQtyInputs.prop('disabled', false);
        //     $freeQtyTargetInfoText.show();
        // }
        //
        // var usedFreeQty = 0;
        //
        // $freeQtyInputFields.each(function () {
        //
        //     var thisQuantity = parseInt($(this).val().replace(/[^0-9]/, ''));
        //
        //     usedFreeQty += thisQuantity;
        //
        //     if (usedFreeQty > freeQty) {
        //         $(this).val(thisQuantity - (usedFreeQty - freeQty));
        //         usedFreeQty = freeQty;
        //     }
        //
        //     if (thisQuantity > 0) {
        //         $(this).removeClass('font-weight-light');
        //         $(this).prev('button').prop('disabled', false);
        //         //$(this).addClass('is-valid');
        //     } else {
        //         $(this).addClass('font-weight-light');
        //         $(this).prev('button').prop('disabled', true);
        //     }
        // });
        //
        // var btnClassActive = 'btn-secondary';
        // var btnClassInactive = 'btn-secondary';
        //
        // if (freeQty < 1) {
        //     $freeQtyTarget.find('button .fa-plus,button .fa-check').addClass('fa-plus').removeClass('fa-check').parent().prop('disabled', true).removeClass(btnClassActive).removeClass('btn-success').addClass(btnClassInactive).parent().find('input')/*.removeClass('is-invalid')*/.removeClass('is-valid');
        // } else if (usedFreeQty >= freeQty) {
        //     $freeQtyTarget.find('button .fa-plus,button .fa-check').removeClass('fa-plus').addClass('fa-check').parent().prop('disabled', true).addClass(btnClassInactive).removeClass(btnClassActive).addClass('btn-success').parent().find('input')/*.removeClass('is-invalid')*/.addClass('is-valid');
        // } else {
        //     $freeQtyTarget.find('button .fa-plus,button .fa-check').addClass('fa-plus').removeClass('fa-check').parent().prop('disabled', false).addClass(btnClassInactive).addClass(btnClassActive).removeClass('btn-success').parent().find('input')/*.addClass('is-invalid')*/.removeClass('is-valid');
        // }

        var $targetPrice = $($this.attr('data-price-sum-target'));

        if ($this.attr('data-special-price')) {
            price = parseFloat($this.attr('data-special-price'));
        }

        if (quantity > 0) {
            $this.prev('button').prop('disabled', false);
            $this.removeClass('font-weight-light');
            $targetPrice.closest('[data-price-parent="true"]').addClass('font-weight-bolder');
            $targetPrice.closest('[data-price-parent="true"]').removeClass('text-muted');
            $targetPrice.closest('[data-price-parent="true"]').removeClass('opacity-50');
            $targetPrice.closest('[data-price-parent="true"]').removeClass('d-none');
            $targetPrice.closest('[data-price-parent="true"]').removeClass('d-md-block');
            // $this.closest('[data-product-row]').removeClass('bg-light').addClass('table-warning');
        } else {
            $this.prev('button').prop('disabled', true);
            $this.addClass('font-weight-light');
            $targetPrice.closest('[data-price-parent="true"]').removeClass('font-weight-bolder');
            $targetPrice.closest('[data-price-parent="true"]').addClass('text-muted');
            $targetPrice.closest('[data-price-parent="true"]').addClass('opacity-50');
            $targetPrice.closest('[data-price-parent="true"]').addClass('d-none');
            $targetPrice.closest('[data-price-parent="true"]').addClass('d-md-block');
            // $this.closest('[data-product-row]').addClass('bg-light').removeClass('table-warning');
        }

        $targetPrice.text(new Intl.NumberFormat(getLocale(), { style: 'currency', currency: getCurrencySymbol() }).format(quantity * price));

        var $targetTotalItems = $($this.attr('data-price-sum-total-items-target'));
        var $targetTotalItemsSpecial = $($this.attr('data-price-sum-total-items-special-target'));
        var $targetTotalDiscount = $($this.attr('data-price-sum-total-discount-target'));
        var $targetTotalAdditionalDiscount = $($this.attr('data-price-sum-additional-discount-target'));
        var $targetTotal = $($this.attr('data-price-sum-total-target'));
        var $targetShipping = $('[data-shipping]');
        var shipping = parseFloat($targetShipping.attr('data-shipping'));
        var freeShippingCutOff = parseFloat($targetShipping.attr('data-free-shipping-cut-off'));
        var $targetFreeShippingHide = $('[data-free-shipping-cut-off-hide]');
        var $targetFreeShippingShow = $('[data-free-shipping-cut-off-show]');
        var totalItems = 0.0;
        var totalItemsSpecial = 0.0;
        var totalDiscount = 0.0;
        var additionalDiscount = null;
        var additionalDiscountValue = $targetTotalAdditionalDiscount.length > 0 ? parseFloat($targetTotalAdditionalDiscount.attr('data-value')) : null;
        var additionalDiscountType = $targetTotalAdditionalDiscount.length > 0 ? $targetTotalAdditionalDiscount.attr('data-type') : null;
        var promotionSkus = $targetTotalAdditionalDiscount.length > 0 ? $targetTotalAdditionalDiscount.data('promotion-skus') : null;
        try {
            if (typeof promotionSkus === 'object') {
                if (promotionSkus.length === undefined || promotionSkus.length < 1) {
                    promotionSkus = null;
                }
            }
        } catch (e) {
            promotionSkus = null;
        }
        var total = 0;
        var totalQuantity = 0;

        $('input[data-price]').each(function () {

            var $this = $(this);
            var price = parseFloat($this.attr('data-price'));
            var specialPrice = parseFloat($this.attr('data-price'));
            var discount = 0.0;
            var quantity = parseInt($this.val().replace(/[^0-9]/, ''));

            if ($this.attr('data-special-price')) {
                discount += parseFloat($this.attr('data-special-price')) - price;
                specialPrice = parseFloat($this.attr('data-special-price'));
            }

            if (!Number.isNaN(quantity)) {
                quantity = Math.max(Math.min(quantity, 9999), 0);
                $this.val(quantity);
            } else {
                quantity = 0;
            }

            totalItems += quantity * price;
            totalItemsSpecial += quantity * specialPrice;
            totalDiscount += quantity * discount;
            total += quantity * specialPrice;
            totalQuantity += quantity;
        });

        if (freeShippingCutOff > 0.0 && totalItemsSpecial > freeShippingCutOff) {
            shipping = 0.0;
        }

        if (shipping === 0.0) {
            $targetFreeShippingHide.addClass('d-none');
            $targetFreeShippingShow.removeClass('d-none');
        } else {
            $targetFreeShippingHide.removeClass('d-none');
            $targetFreeShippingShow.addClass('d-none');
        }

        total += shipping;

        switch (additionalDiscountType) {

            case 'type-total-percent':

                if (additionalDiscountValue !== null && !Number.isNaN(additionalDiscountValue)) {

                    additionalDiscount = 0.0

                    if (promotionSkus) {
                        $('input[data-price]').each(function () {

                            var $this = $(this);

                            if (!promotionSkus.includes($this.attr('data-sku'))) return;

                            var specialPrice = parseFloat($this.attr('data-price'));
                            var quantity = parseInt($this.val().replace(/[^0-9]/, ''));

                            if ($this.attr('data-special-price')) {
                                specialPrice = parseFloat($this.attr('data-special-price'));
                            }

                            if (!Number.isNaN(quantity)) {
                                quantity = Math.max(Math.min(quantity, 9999), 0);
                            } else {
                                quantity = 0;
                            }

                            additionalDiscount += specialPrice * quantity * additionalDiscountValue;
                        });
                    } else {
                        additionalDiscount = total * additionalDiscountValue;
                    }

                    total += additionalDiscount;
                }
                break;

            case 'type-total-amount':

                if (additionalDiscountValue !== null && !Number.isNaN(additionalDiscountValue)) {

                    additionalDiscount = 0.0

                    if (promotionSkus) {
                        $('input[data-price]').each(function () {

                            var $this = $(this);

                            if (!promotionSkus.includes($this.attr('data-sku'))) return;

                            var specialPrice = parseFloat($this.attr('data-price'));
                            var quantity = parseInt($this.val().replace(/[^0-9]/, ''));

                            if ($this.attr('data-special-price')) {
                                specialPrice = parseFloat($this.attr('data-special-price'));
                            }

                            if (!Number.isNaN(quantity)) {
                                quantity = Math.max(Math.min(quantity, 9999), 0);
                            } else {
                                quantity = 0;
                            }

                            additionalDiscount += quantity * additionalDiscountValue;
                        });
                    } else {
                        additionalDiscount = additionalDiscountValue;
                    }

                    total += additionalDiscount;
                }
                break;
        }

        $targetTotalItems.text(new Intl.NumberFormat(getLocale(), { style: 'currency', currency: getCurrencySymbol() }).format(totalItems));
        $targetTotalItemsSpecial.text(new Intl.NumberFormat(getLocale(), { style: 'currency', currency: getCurrencySymbol() }).format(totalItemsSpecial));getLocale()
        $targetTotalDiscount.text(new Intl.NumberFormat(getLocale(), { style: 'currency', currency: getCurrencySymbol() }).format(totalDiscount));
        $targetTotalAdditionalDiscount.text(new Intl.NumberFormat(getLocale(), { style: 'currency', currency: getCurrencySymbol() }).format(additionalDiscount));
        $targetTotal.text(new Intl.NumberFormat(getLocale(), { style: 'currency', currency: getCurrencySymbol() }).format(total));
        $targetShipping.text(new Intl.NumberFormat(getLocale(), { style: 'currency', currency: getCurrencySymbol() }).format(shipping));

        // if (totalQuantity > 0) {
        //     $('[data-button="checkout"]').prop('disabled', false);
        // } else {
        //     $('[data-button="checkout"]').prop('disabled', true);
        // }

        if (interaction_started) {

            var $form = $('[data-update-basket-uri]');
            var uri = $form.attr('data-update-basket-uri');
            var formData = $form.serialize();
            var method = $form.attr('method');

            update_basket_timeout = setTimeout(function () {
                update_basket_xhr = $.ajax({
                    url: uri,
                    method: method,
                    data: formData,
                    success: function (data) {

                        if (data.success) {

                            if (data.update_basket_uri) {
                                $form.attr('data-update-basket-uri', data.update_basket_uri);
                            }

                            if (data.checkout_uri) {
                                $form.attr('action', data.checkout_uri);
                            }

                            if (data.basket_id) {
                                basket_id = data.basket_id;
                            }

                            // if (!location.href.match(/basket_id/)) {
                            //     var url = new URL(window.location);
                            //     url.searchParams.set('basket_id', basket_id);
                            //     window.history.pushState({}, '', url);
                            // }

                            $('[data-load-text-uri]').each(function () {
                                var $this = $(this);
                                var uri = $this.attr('data-load-text-uri');
                                $.getJSON(uri, null, function (data) {
                                    $this.text(data.text);
                                });
                            });
                        }
                    }
                });
            }, 500);
        }

    }).off('blur.quantity').on('blur.quantity', function () {
        var $this = $(this);
        var quantity = parseInt($this.val().replace(/[^0-9]/, ''));
        if (!Number.isNaN(quantity)) {
            quantity = Math.max(Math.min(quantity, 9999), 0);
        } else {
            quantity = 0;
        }
        $this.val(quantity);
    }).trigger('change.quantity');

    $('[data-load-text-uri]').each(function () {
        var $this = $(this);
        var uri = $this.attr('data-load-text-uri');
        $.getJSON(uri, null, function (data) {
            $this.text(data.text);
        });
    });

    $(document).off('show.bs.modal show.bs.offcanvas').on('show.bs.modal show.bs.offcanvas', function (event) {

        var button = $(event.relatedTarget);
        var modal = $(event.target);

        var title = button.attr('title') ? button.attr('title') : button.text();
        if (title) modal.find('.modal-title,.offcanvas-title').html(title);

        var uri = button.data('uri');

        if (!uri) return;

        modal.find('.modal-body > *,.offcanvas-body > *').detach();
        modal.find('.modal-body,.offcanvas-body').append('<div class="d-flex align-items-center justify-content-center"><div class="spinner-border" role="status"></div></div>');

        var loadTarget = '#content';

        if (button.data('load-target')) loadTarget = button.data('load-target');

        modal.find('.modal-body,.offcanvas-body').load(uri + ' '+loadTarget, function (responseText, textStatus, jqXHR) {

            var modalBody = $(this);

            if (jqXHR.status !== 200) {

                modal.find('.modal-title,.offcanvas-title').html('<span class="text-danger"><span class="glyphicon glyphicon-alert"></span> <strong>'+jqXHR.status+' '+jqXHR.statusText+'</strong></span>');

                modalBody.html('<p class="text-danger">Beim Laden der Daten kam es zu einem Fehler. Bitte versuchen Sie es erneut.</p>');
            } else {

                if (button.data('load-modal-scripts')) {

                    var $element = $('<div/>').html(responseText).find('#content');
                    // var $scripts = $element.detach('script');
                    modalBody.html($element.html());

                    // for (var i = 0; i < $scripts.length; i++) {
                    //     var newScriptTag = document.createElement('script');
                    //     newScriptTag.type = 'text/javascript';
                    //     newScriptTag.text = $scripts[i].get(0).text;
                    //     $element.get(0).appendChild(newScriptTag);
                    // }
                } else {
                    var pushDataLayerDom = false;
                    $(this).find('[data-gtag-data]').each(function () {
                        window.gtagEventData = $(this).data('gtag-data');
                        pushDataLayerDom = true;
                    });
                    $(this).find('[data-gtag-ga4-items]').each(function () {
                        window.gtagGa4Items = $(this).data('gtag-ga4-items');
                        pushDataLayerDom = true;
                    });
                    if (pushDataLayerDom) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({event:"gtm.dom"});
                    }
                    $(this).find('[data-add-basket-item]').each(function () {
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                            'event': 'addToCart',
                            'ecommerce': {
                                'currencyCode': $(this).data('currency'),
                                'add': {
                                    'products': $(this).data('add-basket-item')
                                }
                            }
                        });
                        var data = $(this).data('add-basket-item');
                        dataLayer.push({ 'fbTrack': null, 'fbTrackData': null });
                        window.dataLayer.push({
                            'fbTrack': 'AddToCart',
                            'fbTrackData': { contents: data.map(function (item) { return { id: item.id, quantity: 1 }; }), value: $(this).data('gtag-ga4-value'), currency: $(this).data('currency'), content_type: 'product' }
                        });
                    });

                    $(this).find('[data-ecommerce-data-layer-push]').each(function () {
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push($(this).data('ecommerce-data-layer-push'));
                    });

                    $('[data-bs-toggle-collapse-timeout]').each(toggleCollapseTimeout);
                    initElements();
                }
            }
        });
    });

    initElements();

    setTimeout(function () {
        $('[data-replace-preview]:visible').each(function () {
            var $this = $(this);
            if ($this.offset().top < $(document).scrollTop() + $(window).height() && $this.offset().top + $this.outerHeight() > $(document).scrollTop()) {
                var src = $this.attr('src');
                var new_src = $this.data('replace-preview').toString().match(/^1$/) ? src.replace(/\.preview/, '') : $this.data('replace-preview');
                $this.attr('src', new_src);
                $this.removeAttr('data-replace-preview');
            }
        });
    }, 1000);

    setTimeout(function () {
        $('[data-replace-preview]').each(function () {
            var $this = $(this);
            var src = $this.attr('src');
            var new_src = $this.data('replace-preview').toString().match(/^1$/) ? src.replace(/\.preview/, '') : $this.data('replace-preview');
            $this.attr('src', new_src);
            $this.removeAttr('data-replace-preview');
        });
    }, 2500);

    setTimeout(function () {

        $('[data-bs-ride=""][data-load-items="true"]').each(function () {

            var $this = $(this);
            var $elementTemplate = $this.find('.carousel-item');

            $this.removeAttr('data-load-items');

            $this.find('[data-carousel-item-asset-path]').each(function () {

                var $element = $elementTemplate.clone();
                $element.find('.banner-overlay').detach();
                var $img = $element.find('img');

                $element.removeClass('active');
                $img.attr('src', $(this).data('carousel-item-asset-path'));
                $img.attr('onclick', 'window.location = \''+$(this).data('carousel-item-url')+'\';');
                $element.html($(this).html());
                $element.prepend($img);

                $elementTemplate.parent().append($element);

                $(this).detach();
            });

            $this.attr('data-bs-ride', 'carousel');
            $this.carousel();
        });
    }, 3000);

    $('[data-bs-toggle-collapse-timeout]').each(toggleCollapseTimeout);

    initPing();

    (function () {
        let is_touch;
        let na = navigator.userAgent;
        if (na.match(/android|iphone|ipad|mobile|surface/i)) {
            try {
                is_touch = new TouchEvent('touchstart');
            }
            catch (e) {
            }
        }

        const el = document.querySelector('.sticky-toc');

        if (!is_touch) {

            if (el) {
                const observer = new IntersectionObserver(
                    function ([e]) {
                        if (e.intersectionRatio < 1 && e.boundingClientRect.y < 0 && e.intersectionRect.y < 1) {
                            let l = $('main > div.content > div.container-xl');
                            e.target.style.marginLeft = (-l.offset().left) + 'px';
                            e.target.style.width = (l.offset().left - 12) + 'px';
                        } else {
                            e.target.style.marginLeft = null;
                            e.target.style.width = null;
                        }
                        e.target.classList.toggle('is-pinned', e.intersectionRatio < 1 && e.boundingClientRect.y < 0 && e.intersectionRect.y < 1);
                    },
                    {threshold: [1]}
                );
                observer.observe(el);
            }
        }
    })();
});

$(document).ready(function () {

    var is_touch;

    var na = navigator.userAgent;

    if (na.match(/android|iphone|ipad|mobile|surface/i)) {
        try {
            is_touch = new TouchEvent('touchstart');
        }
        catch (e) {
        }
    }

    $(document)
        .off('click.zoom')
        .on('click.zoom', function () {
            $('[data-zoom-target="true"]').detach().html('');
        })
    ;

    if (!is_touch) {

        $('[data-zoom="true"]').each(function () {

            var $this = $(this);
            var $zoom_target = $('<div data-zoom-target="true"/>');
            $zoom_target.addClass('position-absolute');
            $zoom_target.addClass('overflow-hidden');
            $zoom_target.addClass('border');
            $zoom_target.addClass('shadow');
            var $afterImg = null;
            var zoom_value = 2;

            $this
                .off('slid.bs.carousel')
                .on('slid.bs.carousel', function () {
                    if ($afterImg) $afterImg.detach();
                    $afterImg = null;
                    $zoom_target.html('');
                    $zoom_target.detach();
                    // $zoom_target.css('top', $this.offset().top);
                    // $zoom_target.css('left', $this.offset().left + $this.width() + 20);
                    // $zoom_target.css('width', 500);
                    // $zoom_target.css('height', 500);
                    // $afterImg = $this.find('.carousel-item.active').clone();
                    // $zoom_target.append($afterImg);
                    // $afterImg
                    //     .addClass('position-absolute')
                    //     .css('width', Math.max($this.width() * zoom_value, $zoom_target.width()))
                    // ;
                    // $('body').append($zoom_target);
                    // $afterImg.css('left', Math.min(0, Math.max(-$this.width() * zoom_value + $zoom_target.width(), -last_x / ($this.width() * zoom_value) * ($this.width() * zoom_value - $zoom_target.width()) * zoom_value)));
                    // $afterImg.css('top', Math.min(0, Math.max(-$this.height() * zoom_value + $zoom_target.height(), -last_y / ($this.height() * zoom_value) * ($this.height() * zoom_value - $zoom_target.height()) * zoom_value)));
                })
                .off('mouseenter.zoom')
                .on('mouseenter.zoom', function () {
                    if ($afterImg) $afterImg.detach();
                    $afterImg = null;
                    $zoom_target.html('');
                    $zoom_target.detach();
                    $zoom_target.css('top', $this.offset().top);
                    $zoom_target.css('left', $this.offset().left + $this.width() + 20);
                    $zoom_target.css('width', 500);
                    $zoom_target.css('height', 500);
                    $afterImg = $this.find('.carousel-item.active').clone();
                    $zoom_target.append($afterImg);
                    $afterImg
                        .addClass('position-absolute')
                        .css('width', Math.max($this.width() * zoom_value, $zoom_target.width()))
                    ;
                    $('body').append($zoom_target);
                })
                .off('mouseleave.zoom')
                .on('mouseleave.zoom', function () {
                    if ($afterImg) $afterImg.detach();
                    $afterImg = null;
                    $zoom_target.html('');
                    $zoom_target.detach();
                })
                .off('mousemove.zoom').on('mousemove.zoom', function (e) {

                    if (!$afterImg || $afterImg.length < 1) {
                        $zoom_target.html('');
                        $zoom_target.detach();
                        $zoom_target.css('top', $this.offset().top);
                        $zoom_target.css('left', $this.offset().left + $this.width() + 20);
                        $zoom_target.css('width', 500);
                        $zoom_target.css('height', 500);
                        $afterImg = $this.find('.carousel-item.active').clone();
                        $zoom_target.append($afterImg);
                        $afterImg
                            .addClass('position-absolute')
                            .css('width', Math.max($this.width() * zoom_value, $zoom_target.width()))
                        ;
                        $('body').append($zoom_target);
                    }

                    if ($afterImg) {
                        var x = (e.pageX / 1.25 - $this.offset().left) * 1.5;
                        var y = (e.pageY / 1.25 - $this.offset().top) * 1.5;
                        $afterImg.css('left', Math.min(0, Math.max(-$this.width() * zoom_value + $zoom_target.width(), -x / ($this.width() * zoom_value) * ($this.width() * zoom_value - $zoom_target.width()) * zoom_value)));
                        $afterImg.css('top', Math.min(0, Math.max(-$this.height() * zoom_value + $zoom_target.height(), -y / ($this.height() * zoom_value) * ($this.height() * zoom_value - $zoom_target.height()) * zoom_value)));
                    }
                })
            ;
        });
    }

    $('[data-flyout="trigger"]').each(function () {
        var $this = $(this);
        var $menu = $this.find('[data-flyout="menu"]');
        $this
            .off('mouseenter.flyout')
            .on('mouseenter.flyout', function () {
                $menu.addClass('d-lg-block');
            })
            .off('mouseleave.flyout')
            .on('mouseleave.flyout', function () {
                $menu.removeClass('d-lg-block');
            })
        ;
    });
});

function initPing() {
    // try {
    //     var ping_uri = $('[data-ping-uri]').attr('data-ping-uri');
    //     var ping_timeout = parseInt($('[data-ping-timeout]').attr('data-ping-timeout'));
    //     if (Number.isInteger(ping_timeout)) {
    //         setTimeout(function () {
    //             $.ajax(ping_uri, {
    //                 method: 'POST'
    //             }).always(function () {
    //                 initPing();
    //             });
    //         }, ping_timeout);
    //     }
    // } catch (e) { }
}

function initElements() {

    $('img[data-replace-src]').each(function () {

        var $this = $(this);
        var src = $this.attr('data-replace-src');

        var img = new Image();
        img.onload = function() {
            $this.attr('src', src);
        };
        img.src = src;
    });

    $('.modal form[data-submit="in-modal"]').off('submit.modal').on('submit.modal', function (e) {

        e.preventDefault();

        var $form = $(this);
        var $replaceTarget = $form.attr('data-replace-submit-target') ? $($form.attr('data-replace-submit-target')) : null;
        var $reloadTarget = $form.attr('data-reload-target') ? $($form.attr('data-reload-target')) : null;
        var successCall = $form.attr('data-success-call');
        var alwaysCall = $form.attr('data-always-call');
        var $modal = $form.closest('.modal');
        var formData = $form.serialize();
        var uri = $form.attr('action');
        var method = $form.attr('method');
        // var $fileField = $form.find('input[type="file"]');
        //
        // if ($fileField.length > 0) {
        //     formData = new FormData($form.get(0));
        // }

        var ajaxProperties = {
            url: uri,
            type: method,
            data: formData,
            success: function (result, statusText, response) {

                if (response.status === 200) {

                    if (typeof(result) === 'object') {

                        if (result.target_uri) {
                            window.location.href = result.target_uri;
                        }

                        if (typeof alwaysCall !== 'undefined' && typeof eval(alwaysCall) === 'function') {
                            eval(alwaysCall)();
                        }

                    } else {

                        if (typeof successCall !== 'undefined' && typeof eval(successCall) === 'function') {
                            eval(successCall)();
                        }

                        var $element = $('<div/>').html(result).find('#content');

                        if ($replaceTarget === null) $replaceTarget = $modal.find('.modal-body');

                        if ($element.length > 0) $replaceTarget.html($element.html());
                        else $replaceTarget.html(result);

                        var $title = $replaceTarget.find('[data-role="modal-title"]');

                        if ($title.length > 0) $modal.find('.modal-title').html($title.detach());
                    }

                    if ($reloadTarget !== null) {
                        $reloadTarget.each(function () {
                            $(this).html('<div class="d-flex align-items-center justify-content-center"><div class="spinner-border" role="status"></div></div>')
                            $(this).load($(this).data('reload-partial-uri'), function () {
                                initElements();
                            });
                        });
                    }
                }
            },
            complete: function () {
                initElements();
            },
            error: function (response) {
                $modal.find('.modal-title').html('<span class="text-danger"><span class="fas fa-exclamation-triangle"></span> Status: '+response.status+'</span>');
                $modal.find('.modal-body').html('<span class="text-danger">Leider ist ein unerwarteter Fehler aufgetreten!</span>');
            },
            beforeSend: function () {

                var fontSize = '1.5rem';

                if ($replaceTarget === null) {
                    $replaceTarget = $modal.find('.modal-body');
                    fontSize = '3rem;'
                }

                $replaceTarget.html('<div class="d-flex align-items-center justify-content-center"><div class="spinner-border" role="status"></div></div>');
            }
        };

        // if ($fileField.length > 0) {
        //     ajaxProperties.processData = false;
        //     ajaxProperties.contentType = false;
        // }

        $.ajax(ajaxProperties);

        return false;
    });

    $('form[data-submit="modal"]').off('submit.modal').on('submit.modal', function (e) {

        e.preventDefault();

        var $form = $(this);
        var $modal = $($form.attr('data-bs-target'));
        var $modalCloseButton = $modal.find('[data-role="close-modal-button"]');
        var formData = $form.serialize();
        var method = $form.attr('method');
        var uri = $form.attr('action');
        var title = $form.attr('data-title');

        $modal.find('.modal-title').text(title);
        $modal.find('.modal-body').html('<div class="d-flex align-items-center justify-content-center"><div class="spinner-border" role="status"></div></div>');
        $modalCloseButton.hide();
        $modalCloseButton.attr('href', '#');
        $modalCloseButton.removeAttr('data-bs-dismiss');

        $modal.modal('show');

        var loadTarget = '#content';

        if ($form.data('load-target')) loadTarget = $form.data('load-target');

        $.ajax({
            url: uri,
            type: method,
            data: formData,
            success: function (data) {

                var $element = $('<div></div>').append(data).find(loadTarget);

                $modal.find('.modal-body').html($element);

                $modal.find('[data-pay-pal-order]').each(initPayPalButtons);

                var $closeUriElement = $modal.find('[data-close-modal-uri]');

                if ($closeUriElement.length > 0) {
                    $modalCloseButton.attr('href', $closeUriElement.attr('data-close-modal-uri'));
                } else {
                    $modalCloseButton.attr('data-bs-dismiss', 'modal');
                }

                $modalCloseButton.show();

                $('[data-bs-toggle-collapse-timeout]').each(toggleCollapseTimeout);
            }
        });

        return false;
    });

    $('form[data-submit="offcanvas"]').off('submit.offcanvas').on('submit.offcanvas', function (e) {

        try {
            if (e.originalEvent.submitter && $(e.originalEvent.submitter).attr('name') === 'express_checkout') return true;
        } catch (exception) {
            // console.log(exception);
        }

        e.preventDefault();

        var $form = $(this);
        var $offcanvas = $($form.attr('data-bs-target'));
        var $offcanvasCloseButton = $offcanvas.find('[data-bs-dismiss="offcanvas"]');
        var formData = $form.serialize();
        var method = $form.attr('method');
        var uri = $form.attr('data-uri') ? $form.attr('data-uri') : $form.attr('action');
        var title = $form.attr('data-title');

        $offcanvas.find('.offcanvas-title').text(title);
        $offcanvas.find('.offcanvas-body').html('<div class="d-flex align-items-center justify-content-center"><div class="spinner-border" role="status"></div></div>');

        $offcanvas.offcanvas('show');

        var loadTarget = '#content';

        if ($form.data('load-target')) loadTarget = $form.data('load-target');

        $.ajax({
            url: uri,
            type: method,
            data: formData,
            success: function (data) {

                var $element = $('<div></div>').append(data).find(loadTarget);

                $offcanvas.find('.offcanvas-body').html($element);

                $offcanvas.find('[data-ecommerce-data-layer-push]').each(function () {
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push($(this).data('ecommerce-data-layer-push'));
                });

                $offcanvas.find('[data-add-basket-item]').each(function () {
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        'event': 'addToCart',
                        'ecommerce': {
                            'currencyCode': $(this).data('currency'),
                            'add': {
                                'products': $(this).data('add-basket-item')
                            }
                        }
                    });
                    var data = $(this).data('add-basket-item');
                    dataLayer.push({ 'fbTrack': null, 'fbTrackData': null });
                    window.dataLayer.push({
                        'fbTrack': 'AddToCart',
                        'fbTrackData': { contents: data.map(function (item) { return { id: item.id, quantity: 1 }; }), value: $(this).data('gtag-ga4-value'), currency: $(this).data('currency'), content_type: 'product' }
                    });
                });
                $('[data-bs-toggle-collapse-timeout]').each(toggleCollapseTimeout);
                initElements();
            }
        });

        return false;
    });
}

function initPayPalButtons() {

    var $payPalElement = $(this);

    if (typeof paypal === 'undefined') {
        $.ajax({
            url: $payPalElement.attr('data-process-failed-payment-uri'),
            type: 'post',
            data: {additional_data: {error: 'Fehler im JS: Die Variable "paypal" ist nicht definiert. Wahrscheinlich ist das PayPal Script nicht richtig geladen worden.'}}
        });
        return;
    }

    paypal.Buttons({
        createOrder: function(data, actions) {
            return actions.order.create({
                purchase_units: [
                    JSON.parse($payPalElement.attr('data-pay-pal-order'))
                ]
            });
        },
        onShippingChange: function(data, actions) {
            if (data.shipping_address.country_code !== 'DE' && data.shipping_address.country_code !== 'AT') {
                return actions.reject();
            }
            return actions.resolve();
        },
        onError: function (err)  {
            $.ajax({
                url: $payPalElement.attr('data-process-failed-payment-uri'),
                type: 'post',
                data: {additional_data: {error: err}},
                success: function (data) {
                    if (data.target_uri) {
                        window.location.href = data.target_uri;
                    } else {
                        alert('Leider kam es zu einem Fehler. Bitte versuchen Sie es erneut.');
                    }
                }
            });
        },
        onApprove: function(data, actions) {

            $('body').append('<div class="modal-backdrop fade show d-flex align-items-center justify-content-center text-white" style="z-index: 9998;" id="payment-blocker"><div class="spinner-border" role="status" style="z-index: 9999;"></div></div>');

            return actions.order.capture().then(function(details) {
                $.ajax({
                    url: $payPalElement.attr('data-capture-payment-uri'),
                    type: 'post',
                    data: {payment_details: details},
                    success: function (data) {
                        if (data.target_uri) {
                            window.location.href = data.target_uri;
                        } else {
                            window.location.href = $payPalElement.attr('data-success-payment-uri');
                        }
                    }
                });
            });
        }
    }).render('#'+$payPalElement.attr('id'));
}

function toggleCollapseTimeout() {
    var $this = $(this);
    var timeout = parseInt($this.attr('data-bs-toggle-collapse-timeout'));
    $this.removeAttr('data-bs-toggle-collapse-timeout');
    setTimeout(function () {
        if ($this.attr('data-stop-timeout')) return;
        $this.toggleClass('show');
    }, timeout);
    if ($this.attr('data-bs-toggle-attribute')) {
        $this.off('click.toggle-attribute').on('click.toggle-attribute', function () {
            $($this.attr('data-bs-target')).attr($this.attr('data-bs-toggle-attribute'), true);
        });
    }
}

// function hashChangeEvent() {
//     if (window.location.href.match(/#/)) {
//         var $navBar = $('#navbar');
//         var $visibleNavBar = $('#navbar:visible');
//         if ($visibleNavBar.length > 0) {
//             $('[data-bs-target="#navbar"]:visible').trigger('click');
//         }
//         var elementName = window.location.href.split('#')[1];
//         $(document).scrollTop($('#'+elementName).offset().top - ($('header').outerHeight() - ($visibleNavBar.length > 0 && $navBar.offset().left < 50 ? $navBar.outerHeight() : 0)) + 1);
//     }
// }
