
// any CSS you import will output into a single css file (app.css in this case)
import './styles/sleezzz.com.scss';
window.gtmid = 'GTM-W3XCTGQJ';
window.fbid = null;
import './app';

const domReady = (fn) => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', fn);
    } else {
        fn();
    }
}

const imgLoad = (src) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
    });
}

(function () {
    domReady(function () {
        const el = document.getElementById('top');
        const loader = document.getElementById('top-loader');
        if (el) {
            const url = el.getAttribute('data-bg-image-url');
            if (url) {
                imgLoad(url).then(() => {
                    el.style.backgroundImage = `url(${url})`;
                    setTimeout(() => el.classList.remove('opacity-0'), 200);
                    if (loader) {
                        loader.classList.add('opacity-0');
                        setTimeout(() => loader.remove(), 300);
                    }
                });
            }
        }
    });
    domReady(function () {
        const el = document.getElementById('pointer-down');
        if (el) {
            setTimeout(function () {
                el.classList.remove('opacity-0');
                el.classList.remove('mb-5');
                el.classList.add('mb-4');
            }, 1500);
        }
    });
    domReady(function () {
        const menuToggle = document.getElementById('navbar-content');
        if (menuToggle) {
            const navLinks = menuToggle.querySelectorAll('.nav-item');
            const bsCollapse = bootstrap.Collapse.getOrCreateInstance(menuToggle, {toggle: false});
            navLinks.forEach((l) => {
                let el = l.querySelector('a');
                el.addEventListener('click', () => {
                    if (menuToggle.classList.contains('show')) setTimeout(() => bsCollapse.toggle(), 400);
                });
            });
            const logo = document.querySelector('.navbar-brand');
            if (logo) {
                logo.addEventListener('click', () => {
                    if (menuToggle.classList.contains('show')) setTimeout(() => bsCollapse.toggle(), 400);
                });
            }
        }
    });
})();

(function () {
    const sticky_header = document.getElementById('sticky-header');
    const main_logo = document.getElementById('main-logo');
    if (sticky_header && main_logo) {
        const observer_header = new IntersectionObserver(
            function ([e]) {
                e.target.classList.toggle('opacity-0', !(e.intersectionRatio < 1 && e.boundingClientRect.y < 0 && e.intersectionRect.y < 1));
            },
            {threshold: [1]}
        );
        observer_header.observe(sticky_header);
        const observer_logo = new IntersectionObserver(
            function ([e]) {
                document.querySelector('main').classList.toggle('opacity-0', !(e.intersectionRatio < 1 && e.boundingClientRect.y < 0 && e.intersectionRect.y < 1));
            },
            {threshold: [1]}
        );
        observer_logo.observe(main_logo);
    }
})();